<template>
  <div style='width: 100%;height: 100%;position: relative;min-width: 1100px' class='home_content_div'>
    <div class='home_top_div'>
      <div class='top_img_div'><img class='div1-lRImg1' :src='topImg2'></div>
      <div class='top_img_div'><img class='div1-lRImg2' :src='topImg1'> <img class='div1-lRImg3' :src='topImg3'></div>
      <div><img class='div1-lRImg4' :src='topImg4'></div>
    </div>
    <div class='home_top_div2'>
      <div class='top_img_div2in1'><img :src='topImg2in1' class='div2-lRImg1'></div>
      <div class='top_img_div2 div2-lRDiv1'><img class='div2-lRImg' :src='topImg2in2'></div>
      <div class='top_img_div2 div2-lRDiv2'><img class='div2-lRImg' :src='topImg2in3'></div>
      <div class='top_img_div2 div2-lRDiv3'><img class='div2-lRImg' :src='topImg2in4'></div>
      <div class='top_img_div2 div2-lRDiv4'><img class='div2-lRImg' :src='topImg2in5'></div>
    </div>
    <div class='home_top_div3'>
      <div class='top_img_div3'><img :src='topImg3in2' class='div3-lRImg1'></div>
      <div class='top_img_div3'>
        <img :src='topImg3in4' class='div3-lRImg2'>
        <img :src='topImg3in1' class='div3-lRImg3'>
      </div>
      <div class='top_img_div3'><img class='div3-lRImg1' :src='topImg3in3'></div>
    </div>
    <div class='home_top_div4'>
      <div class='home-top-padding'>
        <div class='top_img_div4'><img class='div4-lRImg1' :src='topImg4in1'></div>
        <div class='top_img_div4'><img class='div4-lRImg1' :src='topImg4in2'></div>
      </div>
      <div>
        <div class='top_img_div4'><img class='div4-lRImg2' :src='topImg4in3'></div>
        <div class='top_img_div4'><img class='div4-lRImg2' :src='topImg4in4'></div>
        <div class='top_img_div4'><img class='div4-lRImg2' :src='topImg4in5'></div>
      </div>
    </div>
    <div class='home_top_div4-1'>
      <div class='top_img_div4-1'><img class='div4-lRImg3' :src='topImg4in6'></div>
    </div>
    <div class='home_top_div5'>
      <div style='padding-top: 60px'>
        <div class='home_img_div5 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false"><img class='div5-lRImg1' :src='topImg5in1'></div>
        <div class='home_img_div5 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false"><img class='div5-lRImg1' :src='topImg5in2'></div>
      </div>
      <div>
        <div class='home_img_div5 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false"><img class='div5-lRImg1' :src='topImg5in3'></div>
        <div class='home_img_div5 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false"><img class='div5-lRImg1' :src='topImg5in4'></div>
        <div class='home_img_div5 hover-box' @mouseenter="hovering = true" @mouseleave="hovering = false"><img class='div5-lRImg1' :src='topImg5in5'></div>
      </div>
      <div>
        <div class='home_img_div5'><img class='div5-lRImg2' :src='topImg5in6'></div>
      </div>
      <div>
        <div class='line-div'>
          <div></div><span>认知自我</span>
          <img class='div5-lRImg3' :src='topImg5in7'><span>选择未来</span>
          <div></div>
        </div>
      </div>
    </div>
    <Bottom />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import Navbar from '@/layout/components/Navbar'
import Bottom from '@/layout/components/Bottom_app'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const store = useStore()
const name = ref(store.getters.userInfo.name)
// localStorage.setItem('yq_client_token', 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjUwOCwicm9sZUlkcyI6W10sImNsaWVudElwIjoiOjoxIiwiYXBwS2V5Ijoid2VpbGFpIiwidG9rZW4iOm51bGwsImV4cCI6MTcxMDgzNjY3N30.8IHE_ii7IKRvwSwL1HmiMJ0gLChpToelggnxMozEkSQ')
// localStorage.setItem('timeStamp', '1710750277497')
const logout = () => {
  store.dispatch('user/logout')
}
const topImg1 = require('../../assets/images/home/1-1.png')
const topImg2 = require('../../assets/images/home/1-2.png')
const topImg3 = require('../../assets/images/home/1-3.png')
const topImg4 = require('../../assets/images/home/1-4.png')

const topImg2in1 = require('../../assets/images/home/2-1.png')
const topImg2in2 = require('../../assets/images/home/2-2.png')
const topImg2in3 = require('../../assets/images/home/2-3.png')
const topImg2in4 = require('../../assets/images/home/2-4.png')
const topImg2in5 = require('../../assets/images/home/2-5.png')

const topImg3in1 = require('../../assets/images/home/3-1.png')
const topImg3in2 = require('../../assets/images/home/3-2.png')
const topImg3in3 = require('../../assets/images/home/3-3.png')
const topImg3in4 = require('../../assets/images/home/3-4.png')

const topImg4in1 = require('../../assets/images/home/4-1.png')
const topImg4in2 = require('../../assets/images/home/4-2.png')
const topImg4in3 = require('../../assets/images/home/4-3.png')
const topImg4in4 = require('../../assets/images/home/4-4.png')
const topImg4in5 = require('../../assets/images/home/4-5.png')
const topImg4in6 = require('../../assets/images/home/4-6.png')

const topImg5in1 = require('../../assets/images/home/5-1.png')
const topImg5in2 = require('../../assets/images/home/5-2.png')
const topImg5in3 = require('../../assets/images/home/5-3.png')
const topImg5in4 = require('../../assets/images/home/5-4.png')
const topImg5in5 = require('../../assets/images/home/5-5.png')
const topImg5in6 = require('../../assets/images/home/5-6.png')
const topImg5in7 = require('../../assets/images/home/5-7.png')
const hovering = ref(false)

const isUserInfo = ref(false)
if (name.value !== null && name.value !== undefined && name.value !== '') {
  isUserInfo.value = true
}

// 设置侦听器
onMounted(() => {
  // const Urlparam = new URLSearchParams(window.location.search)
  // console.log(Urlparam.get('param'))
  // console.log('-------')
  // window.addEventListener('message', (event) => {
  //   // if (event.origin)
  //   console.log(event.data.param)
  // })
})
</script>

<style lang="scss" scoped>
.navbarHome {
  height: $navbarHeight;
  overflow: hidden;
  position: fixed;
  width: 100%;
  background: $background-color-primary;
  //box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  border-bottom: $border-base;
  text-align: center;
  z-index: 1000;
  .newbtn{
    margin-top:9px;
    margin-left:25px;
  }
  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    // hover 动画
    transition: background 0.5s;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    align-items: center;
    display: inline-block;
    padding-right: 16px;
    position: fixed;
    /* top: -160px; */
    /* left: 300px; */
    right: 0;
    top: 0;

    :deep(.right-menu-item) {
      display: inline-block;
      padding: 0 5px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;
        line-height: 60px;

        //&:hover {
        //  background: rgba(0, 0, 0, 0.025);
        //}
      }
    }

    :deep(.avatar-container) {
      cursor: pointer;
      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        .el-avatar {
          --el-avatar-background-color: none;
          margin-right: 12px;
        }
      }
    }
  }

  .avatar-container{
    line-height:$navbarHeight;

    .avatar-wrapper {
      margin-top:0px !important;
    }
  }
}
//.line-div{
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  div {
//    width: 260px;
//    border-top: 1px solid white;
//  }
//}
//.line-div span{
//  color: white;
//  font-size: 25px;
//  margin: 0 28px;
//  font-weight: bold;
//}

</style>
